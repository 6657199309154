import { makeStyles } from "@material-ui/core/styles";


//funcion para el textfield
export const useStylesTextField = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(0),
        marginTop: theme.spacing(0),
        width: "25ch",
      },
    },
  }));
  
  //Funcion para el grid
  
export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  }));

export const useStylesSlider = makeStyles({
    root: {
      width: 225,
    },
  });
export const useStylesSpeedDial = makeStyles((theme) => ({
    root: {
      height: 380,
      transform: "translateZ(0px)",
      flexGrow: 1,
    },
    speedDial: {
      position: "absolute",
  
      "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
  }));
  
  //Funcion para cargar Background
  
  export const useStylesBackground = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
  }));
  
  //Funcinon para mostrar imagenes
  
  
  //Funcion para la lista
  export const useStylesList = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 752,
      overflow: "auto",
      position: "relative",
  
      overflowX: "scrolltotop",
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
      margin: -18,
    },
    title: {
      margin: theme.spacing(40, 0, 2),
    },
  }));
import ReactDOM from "react-dom";
import { Roulette } from "./pages/roulette";
import React, { Component } from "react";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import "./styles.css";
import BackOffice from "./pages/backOffice";
import SignIn from "./pages/Signin";
import withFirebaseAuth from "react-with-firebase-auth";
// import * as firebase from 'firebase/app';
// import 'firebase/auth';
import { firebaseApp } from "./service/firebase";
import { StoreProvider } from "./context/store";
import { SnackbarProvider } from 'notistack';
import Collapse from '@material-ui/core/Collapse';
const firebaseAppAuth = firebaseApp.auth();

const App = (props) => {
  // props -> tiene todos los metodos y de 'react-with-firebase-auth' Ej: user, signOut, ...
  const [claims, setClaims] = React.useState({})

  React.useEffect(() => { // Get custom claims and idToken
    if (props.user) {
      firebaseAppAuth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          // console.log('index - idTokenResult.claims', idTokenResult.claims)
          setClaims(idTokenResult.claims)
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setClaims({})
    }
  }, [props.user])

  return (
    <Router>
      <div>
        <Switch>
          <StoreProvider anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
            TransitionComponent={Collapse}
            user={props.user}>
            <SnackbarProvider maxSnack={3}>
              <Route exact path="/" component={() => <SignIn firebase_props={props} claims={claims} firebaseAppAuth={firebaseAppAuth} />} />
              <Route path="/roulette" component={Roulette} />
              {claims.admin ? (
                // <Route path="/backOffice" component={backOffice} />
                <Route path="/backOffice" component={() => <BackOffice firebase_props={props} claims={claims}/>} />
              ) : (
                <Redirect to="/" />
              )}
            </SnackbarProvider>
          </StoreProvider>
        </Switch>
      </div>
    </Router>
  );
}

const App_ = withFirebaseAuth({
  // providers,
  firebaseAppAuth,
})(App);

const rootElement = document.getElementById("root");
ReactDOM.render(<App_ />, rootElement);




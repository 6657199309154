import db from './firebase';
import { firebaseApp } from "./firebase";
const firebaseAppAuth = firebaseApp.auth();


export const enableFirestorePersistence = db => {
  db.enablePersistence()
    .then(() => {
      console.log('Firestore persistence ready');
    })
    .catch(err => {
      console.log('Firestore persistence error', err.code);
    });
};

export default class dbController {
  constructor(firebase, enablePer = false) {
    this.db = db;
    if (enablePer) {
      enableFirestorePersistence(this.db);
    }
  }

  createObject = (collection, data, callback) => {
    this.db
      .collection(collection)
      .add(data)
      .then(data_ => {
        callback({ error: false, data: data_ });
      })
      .catch((reason) => {
        callback({ error: true, data: reason })
      });
  };

  getObjectsIdSnap = (collection, id, callback) => {
    this.db
      .collection(collection)
      .doc(id)
      .onSnapshot((doc) => {
        callback({
          id: doc.id,
          data: doc.data(),
        });
      });
  };

  getObjectsId = (collection, id, callback) => {
    this.db
      .collection(collection)
      .doc(id)
      .get().then((doc) =>
        callback({
          id: doc.id,
          data: doc.data(),
        })


      )
  };

  getObjectsSnap = (collection, callback) => {
    const unsuscribe = this.db.collection(collection).onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        const doc = change.doc;
        callback({ id: doc.id, data: doc.data(), type: change.type });
      });
    });
    return unsuscribe;
  };

  editFirstLevel = (id, collection, data, callback) => {
    this.db
      .collection(collection)
      .doc(id)
      .update(data)
      .then(() => callback({ error: false, data }))
      .catch((error) => {
        callback({ error: true, data: error });
      });
  };

  deleteDoc = (collection, id, callback) => {
    this.db
      .collection(collection)
      .doc(id)
      .delete()
      .then(result => {
        callback({ error: false, result });
      })
      .catch(reason => {
        callback({ error: true, result: reason });
      });
  };

  sendImage = (data, callback) => {
    firebaseAppAuth.currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          'https://us-central1-warden-roulette.cloudfunctions.net/wally/upload/image',
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({
              base64image: data.imageBase64,
              tokenId: idToken,
            }),
          },
        )
          .then(response => response.json())
          .then(data => {
            callback(data);
          })
          .catch(error => console.log(error));
      }).catch((error) => {
        console.log(error);
      });

  };

  deleteImage = (data, callback) => {
    firebaseAppAuth.currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          'https://us-central1-warden-roulette.cloudfunctions.net/wally/delete/image',
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({
              imageUrl: data.imageUrl,
              tokenId: idToken,
            }),
          },
        )
          .then(response => response.json())
          .then(data => {
            callback(data);
          })
          .catch(error => console.log(error));
      }).catch((error) => {
        console.log(error);
      });
  };

}
import { uuid } from "uuidv4";
export const setRouletteStyles=(data)=>{
    document.documentElement.style.setProperty(
        "--wheel-size",
        data.roulette_size_1 + "px"
      );
      document.documentElement.style.setProperty(
        "--wheel-slice-spacing",
        data.roulette_div_size + "px"
      );
      document.documentElement.style.setProperty("--nb-turn", data.spins_turns);
      document.documentElement.style.setProperty(
        "--spinning-duration",
        data.spins_duration + "s"
      );
      document.documentElement.style.setProperty(
        "--wheel-border-size",
        data.roulette_border_size + "px"
      );
      document.documentElement.style.setProperty(
        "--wheel-color",
        data.wheel_color_1
      );
      document.documentElement.style.setProperty(
        "--neutral-color",
        data.wheel_color_2
      );
      document.documentElement.style.setProperty(
        "--color-text-1",
        data.wheel_text_color_1
      );
      document.documentElement.style.setProperty(
        "--color-text-2",
        data.wheel_text_color_2
      );
      document.documentElement.style.setProperty(
        "--logo_background_size",
        data.logo_size + "px"
      );
      document.documentElement.style.setProperty(
        "--wheel-font-size",
        data.font_size + "px"
      );
      
      // document.documentElement.style.setProperty(
      //   "background",
      //   "url("+data.background_file+") center no-repeat"
      // );
}





export const handleProbabilitys = (event, probability, payload = {}) => {
  let probabilityi = [...probability];
  const { id, data } = payload;
  const randid=uuid()
  switch (event) {
    case "add": {
      probabilityi.push({
        id: randid,
        label: "",
        prize: "",
        probability: 1,
      });
      break;
    }
    case "delete": {
      probabilityi = probabilityi.filter((item) => item.id !== id);
      break;
    }
    case "edit": {
      const selected = probabilityi.find((item) => item.id === id);
      selected.label = data.label;
      selected.prize = data.prize;
      selected.probability = data.probability;
      break;
    }
  }
  return probabilityi;
};



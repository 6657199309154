import React from 'react';
import ReactDOM from 'react-dom';
import Wheel from '../components/preview/index preview';
import '../styles.css';

export function RoulettePreview({items=['10.000 Gs.', '20.000 Gs.', '30.000 Gs.', '40.000 Gs.', '50.000 Gs.'],}){
  const items_= items;
  return(
    <div className="content">       
    <h1 className="RouletteText" >GIRÁ Y GANÁ</h1>
    <h1 className="RouletteText2" ><strong>CON LA RULETA DE WALLY</strong></h1>
    <Wheel items={items_} /> 
  </div>
    )
 
}


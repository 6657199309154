import React from 'react';
import { Button, CssBaseline, TextField, Link, Box, Typography, Container, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";

import wally_logo_signin from '../assets/img/wally_login.png'
function Copyright() {
  return (
    // <Typography variant="body2" color="textSecondary" align="center">
    //   {'Copyright © '}
    //   <Link color="inherit" href="https://material-ui.com/">
    //     Warden Automation
    //   </Link>{' '}
    //   {new Date().getFullYear()}
    //   {'.'}
    // </Typography>
    (null)
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const [data, setData] = React.useState({ username: "", password: "" })
  const [forgot, setForgot] = React.useState(false)


  const login = () => {
    props.firebase_props.signInWithEmailAndPassword(data.username, data.password)
      .then((userCredential) => {
        const user = userCredential.user;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }

  const SignIn = () => {
    props.firebase_props.createUserWithEmailAndPassword(data.username, data.password)
      .then((userCredential) => {
        const user = userCredential.user;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }

  const mainRender = () => {
    return (
      <Container component="main" maxWidth="xs" style={{ height: '100vh' }}>
        <CssBaseline />
        <div className={classes.paper}>
          <img src={wally_logo_signin} style={{borderRadius:"20px"}}/>
          <br/>
          <Typography component="h1" variant="h5">
            Iniciar sesión
          </Typography>
          <div className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(event) => { setData({ ...data, username: event.target.value }) }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="password"
              onChange={(event) => setData({ ...data, password: event.target.value })}
            />

            <Grid container spacing={2} justifyContent="space-around">
              
              <Grid item >
              <br/>
                <Button variant="contained" color="primary" onClick={() => { login() }} >
                  Login
                </Button>
              </Grid>
              {/* <Grid item >
                <Button variant="contained" color="primary" onClick={() => { SignIn() }} >
                  Sign In
                </Button>
              </Grid> */}
              {/* <Grid item >
                <Button variant="contained" color="primary" onClick={() => { props.firebase_props.signOut() }} >
                  LogOut
                </Button>
              </Grid> */}
            </Grid>
            <Button variant="text" onClick={() => { setForgot(!forgot) }}>
              Olvidaste tu contraseña?
            </Button>
            <div hidden={!forgot}>
              <TextField
                variant="outlined"
                fullWidth
                id="email_forgot"
                label="email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(event) => { setData({ ...data, forgot: event.target.value }) }}
              />    
              <Button variant="contained" color="primary" onClick={() => props.firebaseAppAuth.sendPasswordResetEmail(data.forgot)} >
                Send
              </Button>
            </div>
          </div>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }

  return (<>
    {props.claims?.admin ? (
      <Redirect to="/backOffice" />
    ) : (
      mainRender()
    )}
  </>)


}
import swal from "@sweetalert/with-react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import "../../pages/backOffice.css";

const DeleteBackground = (ok_fun,cancel_fun) => {
  swal(
    <span>
      ¿Está seguro que desea eliminar la imagen seleccionada?
    </span>
    ,
    {
      title: "Eliminar imagen de background",
      className: "modal_campaign",
      buttons: { ok: "Eliminar", cancel: "Cancelar" },
    }
  ).then((value) => {
    value === "ok"
      ? ok_fun()
      : cancel_fun();
  });
}

export {DeleteBackground}
import React, { useEffect, useState } from "react";
import { RoulettePreview } from "./roulette preview";
import "./backOffice.css";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import Block from "@uiw/react-color-block";
import Help from "@material-ui/icons/Help";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import BackgroundImage from "../assets/img/1712455.jpg";
//Base de datos
import dbController from "../service/dbController";
import swal from "@sweetalert/with-react";

//Selector modal campaña
import Select from "@material-ui/core/Select";
import { useStore } from "../context/store";
import { ReactComponent as ReactLogo } from "../assets/img/edit_logo.svg";
import { handleProbabilitys, setRouletteStyles } from "../utils/roulette";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";

import ImageListItem from '@material-ui/core/ImageListItem';
import ImageList from '@material-ui/core/ImageList';
//boton anhadir premio
import {
  Add,
  Clear,
  AddCircleOutline,
  DeleteForever,
  Save,
  Pageview,
  MeetingRoom,
  Description,
} from "@material-ui/icons";

//header
import "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Create from "@material-ui/icons/Create";
import {
  useStyles,
  useStylesBackground,
  useStylesList,
  useStylesSlider,
  useStylesTextField,
} from "../styles/material/roulette";
//switch Publicar
import Switch from "@material-ui/core/Switch";
import BackgroundList from "../components/backOffice/BackgroundList";
import { useSnackbar } from "notistack";
import Collapse from "@material-ui/core/Collapse";
//Funciones para el navbar

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import LinearProgress from '@material-ui/core/LinearProgress';

import {DeleteBackground} from '../components/backOffice/Alerts'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const defaultRoulleteValues = {
  roulette_size_1: 290,
  roulette_size_2: 290,
  roulette_div_size: 60,
  logo_size: 60,
  spins_turns: 5,
  spins_duration: 4,
  font_size: 18,
  roulette_border_size: 10,
  wheel_color_1: "#1fc4d0",
  wheel_color_2: "#FFFFFF",
  wheel_text_color_1: "#FFFFFF",
  wheel_text_color_2: "#000000",
  play_again: false,
  play_again_count: 1,
  background_file: "",
  campaign_active: "Campaña 2",
};

export default function ScrollableTabsButtonForce(props) {
  ///////////////////////////Variables que se usan////////////////////////
  const campaigns = useStore().data.campaign;
  const userData = useStore().data.user_data;
  const generalSetting = useStore().data.settings;
  React.useEffect(() => {
    setCampaign_active(generalSetting.general?.campaign_active);
    handleCampaign_edit();
    sumProbabilitys(); ///suma de las probabilidades al cargar una campanha
  }, [useStore().data]);
  const [data, setData] = React.useState({ ...defaultRoulleteValues });
  const [probabilitys, setProbabilitys] = React.useState([]);
  const [campaign_active, setCampaign_active] = React.useState(null);


  const [sumOfProbabilitys,setsumOfProbabilitys]= React.useState(0)
  const [uploadbackground64, setUploadbackground64] = React.useState(null);
  const [uploadbackground, setUploadbackground] = React.useState(null);
  const [testbackground, setTestbackground] = React.useState(BackgroundImage);
  //////////trae el background seleccionado de otro componente
  const ImportBackground=(selectedBackground)=>{
    setTestbackground(selectedBackground);
  }


  const sumProbabilitys=()=>{
      let sum = 0;
      probabilitys.map(
             (item) => (sum = sum + item.probability)
                  );
                  setsumOfProbabilitys(sum)    
    return sum;
  }
  const convertImgToBase64=(event)=>{
    var file = event.target.files[0]; // Tomamos la imagen del InputFile
    var reader = new FileReader(); // Creamos un objeto FileReader
    reader.readAsDataURL(file)
    return (reader)
    
  }
  
  const handleCampaign_edit = () => {
    const id = campaign_edit.current;
    const olddata = { ...data };
    const selectedCampaign = campaigns[id] || {};
    olddata.roulette_size_1 =
      selectedCampaign.roulette_size_1 || defaultRoulleteValues.roulette_size_1;
    olddata.roulette_size_2 =
      selectedCampaign.roulette_size_2 || defaultRoulleteValues.roulette_size_2;
    olddata.roulette_div_size =
      selectedCampaign.roulette_div_size ||
      defaultRoulleteValues.roulette_div_size;
    olddata.logo_size =
      selectedCampaign.logo_size || defaultRoulleteValues.logo_size;
    olddata.spins_turns =
      selectedCampaign.spins_turns || defaultRoulleteValues.spins_turns;
    olddata.spins_duration =
      selectedCampaign.spins_duration || defaultRoulleteValues.spins_duration;
    olddata.font_size =
      selectedCampaign.font_size || defaultRoulleteValues.font_size;
    olddata.roulette_border_size =
      selectedCampaign.roulette_border_size ||
      defaultRoulleteValues.roulette_border_size;
    olddata.wheel_color_1 =
      selectedCampaign.wheel_color_1 || defaultRoulleteValues.wheel_color_1;
    olddata.wheel_color_2 =
      selectedCampaign.wheel_color_2 || defaultRoulleteValues.wheel_color_2;
      olddata.wheel_text_color_1 = selectedCampaign.wheel_text_color_1 || defaultRoulleteValues.wheel_text_color_1;
    olddata.wheel_text_color_2 = selectedCampaign.wheel_text_color_2 || defaultRoulleteValues.wheel_text_color_2;
    olddata.play_again_count =
      selectedCampaign.play_again_count ||
      defaultRoulleteValues.play_again_count;
    olddata.play_again =
      selectedCampaign.play_again || defaultRoulleteValues.play_again;
    olddata.background_file =
      selectedCampaign.background_file || defaultRoulleteValues.background_file;
    olddata.campaign_active =
      selectedCampaign.campaign_active || defaultRoulleteValues.campaign_active;
    setRouletteStyles(olddata);
    setData({ ...olddata });
    setProbabilitys(selectedCampaign.setting_data || []);
    setTestbackground(selectedCampaign.background_file || "");
  };
  ////////////// campaña editando//////////////////
  const campaign_edit = React.useRef("");

  if (campaign_edit.current==="" && Object.entries(campaigns)[0] !== (undefined)) //condicional para que cargue la primera campaña de la base de datos
  {
    campaign_edit.current=Object.entries(campaigns)[0][0]
  }

    

  const handleDEdit = (key, e) => {
    const oldData = { ...data };
    const value = e[1];
    oldData[key] = value;
    setData(oldData);
  };
  const classes = useStyles();
  const classesTextField = useStylesTextField();
  const [value, setValue] = React.useState(0);
  const classesSlider = useStylesSlider();
  const classesList = useStylesList();
  const classesBackground = useStylesBackground();
  const [dense, setDense] = React.useState(false);
  const history = useHistory(); //estado para redireccion, nos e usa
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loadingSpinner,setLoadingSpinner]= React.useState(false);
  //////////////////funcion para cambiar entre pestañas///////////////////////
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //////////////funcion para cambiar entre campañas///////////
  const handle_Change_Campaign_Edit = (event) => {
    if (event.target.value == "") {
      campaign_edit.current = Object.entries(campaigns)[0][0];
    } else {
      campaign_edit.current = event.target.value;
    }
    handleCampaign_edit();
  };
  
  const addCampaign = (campaignLabel) => {  
    const campaignNew = { ...defaultRoulleteValues };
    if( campaignLabel !=="" && campaignLabel!==" " && campaignLabel!=="  "){
      campaignNew.setting_data = [];
      campaignNew.campaign_label = campaignLabel;
      const dbCon = new dbController();
      dbCon.createObject("campaign", campaignNew, (response) => {
        const { error, data } = response;
        if (!error) {
          enqueueSnackbar("Campaña creada correctamente.", {
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            TransitionComponent: Collapse,
            variant: "success",
          });
        };
      campaign_edit.current=Object.entries(campaigns).filter((items)=>(items[1].campaign_label===campaignLabel))[0][0] || null; //selecciona la  campaña creada en la base de datos
      handleCampaign_edit(); //carga los valores de la primera campaña de la base de datos

      })
    }
    else{
      enqueueSnackbar("Error al guardar los cambios. Nombre de campaña inválido", {
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        TransitionComponent: Collapse,
        variant: "error",
      });
    }
    ;
  };
  const deleteCampaign = (id) => {
    const dbCon = new dbController();
    dbCon.deleteDoc("campaign", id, (response) => {
      if (!response.error) {
         enqueueSnackbar("Campaña eliminada.", {
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          TransitionComponent: Collapse,
          variant: "info",       
        });
        campaign_edit.current=Object.entries(campaigns)[0][0]; //selecciona la primera campaña de la base de datos
        handleCampaign_edit(); //carga los valores de la primera campaña de la base de datos
      }
    });    
  };
  const editFieldsdb = (id, data, callback_bool, collection = "campaign") => {
    const dbCon = new dbController();
    dbCon.editFirstLevel(id, collection, data, (response) => {
      if (!callback_bool) {
        !response.error
          ? enqueueSnackbar("Se guardaron los cambios correctamente.", {
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
              TransitionComponent: Collapse,
              variant: "success",
            })
          : enqueueSnackbar("Error al guardar los cambios.", {
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
              TransitionComponent: Collapse,
              variant: "error",
            });
      }
    });
  };
  const viewCampaignData = (id) => {
    //funcion para filtrar las campañas por nombre y mostrar en un modal
    const data = Object.entries(userData).filter((item) =>
      item.find((field) => field.campaign === id)
    );
    //se convierte el objeto userData a array, se recorre ese array, por cada elemento de ese array se busca si contiene como valor de "campaign" el valor que se le paso a la funcion
    const rows = data?.map((row) => [
      [row[1]?.ci_number],
      [row[1]?.phone_number],
      [row[1]?.prize.label],
      [new Intl.DateTimeFormat("es-ES").format(row[1].date?.seconds * 1000)],
    ]);
    const columns = [
      "Numero de Cedula",
      "Numero de Celular",
      "Premio",
      "Fecha",
    ];
    const options = {
      filterType: "checkbox",
    };
    swal(
      <div>
        <MUIDataTable
          title={campaigns[campaign_edit.current]?.campaign_label}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>,
      {
        className: "swalModalDatos",
        buttons: {
          Cerrar: "Cerrar",
        },
      }
    );
  };
  ///////////////////actualizar valores del css//////////////////////////////
  useEffect(() => {
    setRouletteStyles(data);
  }, [data]);
  console.warn = console.error = () => {};

  const sendImage = () =>{
    setLoadingSpinner(true)
    const sendImageData = {
      imageBase64: uploadbackground64?.result
    }
    const dbCon = new dbController();
    dbCon.sendImage( sendImageData, (resp) => {
      
      setLoadingSpinner(false)
      setTestbackground("");setUploadbackground("");setUploadbackground64("")

    });
    
    
  }

  const deleteImage = () => {
    const deleteImageData = {
      imageUrl: testbackground
    }
    const dbCon = new dbController();
    dbCon.deleteImage( deleteImageData, (resp) => {
      console.log("deleteImage - resp: ",resp)
    });
  }

  return (
    <div className="backofficestyle">
     
        <div>
          <Grid item xs={12}>
            <div className="img-responsive">
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ReactLogo className="titulo1" />
                </Grid>
                {/* Selector de campañas */}
                <Grid item xs={2}>
                  <div className="Titulo2">
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="textSelectorEditing"
                      >
                        Editando
                      </InputLabel>
                      <Select
                        className="Titulo2"
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={campaign_edit.current}
                        label="Editando"
                        autoWidth
                        onChange={handle_Change_Campaign_Edit}
                      >
                        {Object.entries(campaigns).map((item) => {
                          const id = item[0];
                          const data = item[1].campaign_label;
                          return <MenuItem value={id}>{data} </MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                    <div style={{ marginTop: 20, display: "inline" }}>
                      <IconButton
                        onClick={() => {
                          swal(
                            <Grid container>
                              <Grid item xs={6}>
                                <span
                                  style={{ float: "right", paddingTop: 28 }}
                                >
                                  De{" "}
                                  {
                                    campaigns[campaign_edit.current]
                                      ?.campaign_label
                                  }{" "}
                                  a
                                </span>
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  id="labelNameCampaign"
                                  defaultValue={""}
                                  label={"Nombre de la Campaña"}
                                />
                                {/* </Grid> */}
                              </Grid>
                            </Grid>,
                            {
                              title: "Cambiar Nombre de Campaña",
                              className: "modal_campaign",
                              buttons: { ok: "Guardar", cancel: "Cancelar" },
                            }
                          ).then((value) => {
                            value === "ok"
                              ? editFieldsdb(campaign_edit.current, {
                                  campaign_label:
                                    document.getElementById("labelNameCampaign")
                                      .value,
                                })
                              : null;
                          });
                        }}
                      >
                        <Create color="primary" />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className="Titulo3">
                    <span> Publicar</span>
                    <Switch
                      checked={
                        campaign_edit.current ===
                        generalSetting.general?.campaign_active
                          ? true
                          : false
                      }
                      color="primary"
                      onChange={(event) =>{
                        swal(
                          <div>
                            De{" "}
                            {campaigns[campaign_active]?.campaign_label ||
                              "Ninguno"}{" "}
                            a{" "}
                            {campaign_edit.current ===
                            generalSetting.general?.campaign_active
                              ? "Ninguno"
                              : campaigns[campaign_edit.current]
                                  ?.campaign_label}
                          </div>,
                          {
                            title: "Desea cambiar la campaña Actual?",
                            buttons: {
                              ok: "Ok",
                              cancel: "Cancelar",
                            },
                          }
                        ).then((value) => {
                          if (value === "ok") {
                            campaign_edit.current ===
                            generalSetting.general?.campaign_active
                              ? (editFieldsdb(
                                  "general",
                                  { campaign_active: null },
                                  true,
                                  "settings"
                                ),
                                setCampaign_active(null))
                              : (editFieldsdb(
                                  "general",
                                  { campaign_active: campaign_edit.current },
                                  true,
                                  "settings"
                                ),
                                setCampaign_active(campaign_edit.current));

                                 ///////Guardado en el cambio//////////
                                let sum = 0;
                                probabilitys.map(
                                  (item) => (sum = sum + item.probability)
                                );
                                sum === 100
                                  ? (editFieldsdb(
                                      campaign_edit.current,
                                      {
                                        setting_data: probabilitys,
                                      },
                                      true
                                    ),
                                    editFieldsdb(campaign_edit.current, data),
                                    editFieldsdb(
                                      campaign_edit.current,
                                      { play_again: data.play_again },
                                      true
                                    ),
                                    editFieldsdb(
                                      campaign_edit.current,
                                      { background_file:testbackground },
                                      true
                                    ),
                                    setData({...data,background_file:testbackground})
                                    )
                                  : enqueueSnackbar(
                                      "La suma de las probabilidades debe ser 100.",
                                      {
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "center",
                                        },
                                        TransitionComponent: Collapse,
                                        variant: "error",
                                      }
                                  );
                               }
                         });
                      }}
                    />
                    <br></br>
                    <br></br>
                    <div>
                      <span>
                        Actual:{" "}
                        {campaigns[campaign_active]?.campaign_label ||
                          "Ninguno"}
                      </span>
                    </div>
                  </div>
                </Grid>
                {/* <Grid item xs={1}>
                  <div className="Titulo2">
                    <IconButton onClick={() => history.push("/roulette")}>
                      <Pageview color="primary" fontSize="large" />
                    </IconButton>
                    <br></br>
                    <span> Vista Previa</span>
                  </div>
                </Grid> */}
                <Grid item xs={1}>
                  <div className="Titulo2">
                    <IconButton
                      onClick={() => {
                        swal(
                          <Grid container>
                            <Grid item xs={12}>
                              <TextField
                                id="labelCampaign"
                                defaultValue={""}
                                label={"Nombre de la Campaña"}
                              />
                            </Grid>
                          </Grid>,
                          {
                            title: "Agregar nueva Campaña",
                            className: "modal_campaign",
                            buttons: { ok: "Guardar" },
                          }
                        ).then((value) => {
                          value === "ok"
                            ?
                            
                            addCampaign(
                                document.getElementById("labelCampaign").value
                              )
                            : null;
                        });
                      }}
                    >
                      <AddCircleOutline color="primary" fontSize="large" />
                    </IconButton>
                    <br></br>
                    <span> Nueva Campaña</span>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className="Titulo2">
                    <IconButton
                      onClick={() => {
                        swal(
                          <div>
                            La campaña{" "}
                            {campaigns[campaign_edit.current]?.campaign_label}{" "}
                            se eliminara.
                          </div>,
                          {
                            title:
                              "Desea eliminar la campaña que se esta editando?",
                            buttons: {
                              ok: "Eliminar",
                              cancel: "Cancelar",
                            },
                          }
                        ).then((value) => {
                          if (value === "ok") {
                            deleteCampaign(campaign_edit.current, true);
                          }
                        });
                      }}
                    >
                      <DeleteForever color="secondary" fontSize="large" />
                    </IconButton>
                    <br></br>
                    <span> Eliminar Campaña</span>
                  </div>
                </Grid>

                <Grid item xs={1}>
                  <div className="Titulo2">
                    <IconButton
                      onClick={() => {
                        let sum = 0;
                        probabilitys.map(
                          (item) => (sum = sum + item.probability)
                        );

                          sum === 100
                          ? (editFieldsdb(
                              campaign_edit.current,
                              {
                                setting_data: probabilitys,
                              },
                              true
                            ),
                            editFieldsdb(campaign_edit.current, data),
                            editFieldsdb(
                              campaign_edit.current,
                              { play_again: data.play_again },
                              true
                            ),
                            editFieldsdb(
                              campaign_edit.current,
                              { background_file:testbackground },
                              true
                            ),
                            setData({...data,background_file:testbackground})
                            )
                          : enqueueSnackbar(
                              "La suma de las probabilidades debe ser 100.",
                              {
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "center",
                                },
                                TransitionComponent: Collapse,
                                variant: "error",
                              }
                            );
                      }}
                    >
                      <Save color="primary" fontSize="large" />
                    </IconButton>
                    <br></br>
                    <span>Guardar </span>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className="Titulo2">
                    <IconButton
                      onClick={() => {
                        viewCampaignData(campaign_edit.current);
                      }}
                    >
                      <Description color="primary" fontSize="large" />
                    </IconButton>
                    <br></br>
                    <span>Ver Datos</span>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className="Titulo2">
                    <IconButton
                      onClick={() => {
                        props.firebase_props.signOut();
                      }}
                    >
                      <MeetingRoom color="primary" fontSize="large" />
                    </IconButton>
                    <br></br>
                    <span>Salir</span>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <br />
          <br />
          <br />
        </div>
        <Grid container spacing={6}>
          <Grid item xs={6} className="configSide">
            <AppBar position="static" color="default">
              <Tabs
                className="navbar"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
              >
                <Tab
                  className="Pestañas"
                  label="Probabilidades"
                  {...a11yProps(0)}
                />
                <Tab
                  className="Pestañas"
                  label="Apariencia"
                  {...a11yProps(1)}
                />
                <Tab
                  className="Pestañas"
                  label="Background"
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>
            {/* Primera Pestaña */}
            <TabPanel value={value} index={0}>
              {/* Fila  1 */}
              <Grid container spacing={2}>
                {/* Primera columna */}
                <Grid item xs>
                  <div style={sumOfProbabilitys!==100 ? {color:"red"}:{color:"black"}}>
                  <h4>Probabilidad actual</h4>
                  {
                    sumOfProbabilitys 
                  }%
                  </div>
                </Grid>
                {/* Segunda columna */}
                <Grid item xs>
                  <h4> Volver a jugar</h4>
                  <Switch
                    checked={data.play_again}
                    color="primary"
                    onChange={(event) => 
                        setData({ ...data, play_again: event.target.checked })
                    }
                  />
                </Grid>
              </Grid>
              {/* Fila  2 */}
              <Grid container spacing={2}>
                {/* Primera columna */}
                <Grid item xs>
                  <h4> Premio</h4>
                </Grid>
                {/* Segunda columna */}
                <Grid item xs>
                  <h4> Probabilidad (en %)</h4>
                </Grid>
              </Grid>
              {/* Fila  3 */}
              <div className={classesList.demo} className="scroller">
                <List dense={dense}>
                  {probabilitys?.map((item) => (
                    <ListItem className="list_Of_Items">
                      {/* Filas */}
                      <Grid container spacing={2}>
                        {/* Primera columna */}
                        <Grid item xs>
                          <form
                            className={classesTextField.root}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              value={item.label}
                              onChange={(e) => {
                                setProbabilitys(
                                  handleProbabilitys("edit", probabilitys, {
                                    id: item.id,
                                    data: { ...item, label: e.target.value },
                                  }),
                                  sumProbabilitys()
                                ),
                                  setProbabilitys(
                                    handleProbabilitys("edit", probabilitys, {
                                      id: item.id,
                                      data: { ...item, prize: e.target.value },
                                    })
                                  );
                              }}
                            />
                          </form>
                        </Grid>
                        {/* Segunda columna */}
                        <Grid item xs>
                          <Slider
                            className={classesSlider.root}
                            value={item.probability}
                            valueLabelDisplay="auto"
                            step={1}
                            track="inverted"
                            marks
                            min={0}
                            max={100}
                            onChange={(event, value) => {
                              setProbabilitys(
                                handleProbabilitys("edit", probabilitys, {
                                  id: item.id,
                                  data: { ...item, probability: value },
                                }),
                                sumProbabilitys()
                              );
                            }}
                          />
                          <div style={{ display: "inline-flex", marginInline:"20px" }}>
                            <IconButton
                              color="secondary"
                              onClick={() =>
                                setProbabilitys(
                                  handleProbabilitys("delete", probabilitys, {
                                    id: item.id,
                                  })
                                )
                              }
                            >
                              <Clear className="eraseprizebutton" />
                            </IconButton>
                          </div>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                  <Grid item xs>
                    <IconButton
                      color="secondary"
                      aria-label="add an alarm"
                      color="primary"
                      onClick={() => {
                        setProbabilitys(
                          handleProbabilitys("add", probabilitys)
                        );
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </List>
                <br />
              </div>
            </TabPanel>
            {/* Segunda Pestaña */}
            <TabPanel value={value} index={1}>
              <div className="scroller1">
                <Grid container spacing={2}>
                  {/* Primera columna */}
                  <Grid item xs>
                    <h4>Tamaño de las divisiones</h4>
                    <Slider
                      className={classesSlider.root}
                      valueLabelDisplay="auto"
                      track="inverted"
                      marks
                      min={1}
                      max={180}
                      value={data.roulette_div_size}
                      onChange={(event, value) => {
                        handleDEdit("roulette_div_size", [0, value]);
                      }}
                    />
                  </Grid>
                  {/* Segunda columna */}
                  <Grid item xs>
                    <h4>Tamaño del logo</h4>
                    <Slider
                      className={classesSlider.root}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      track="inverted"
                      step={1}
                      marks
                      min={1}
                      max={110}
                      value={data.logo_size}
                      onChange={(...e) => {
                        handleDEdit("logo_size", e);
                      }}
                    />
                  </Grid>
                </Grid>
                {/* Fila  3 */}
                <Grid container spacing={2}>
                  {/* Primera columna */}
                  <Grid item xs>
                    <h4>Numero de vueltas</h4>
                    <Slider
                      className={classesSlider.root}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={1}
                      max={12}
                      value={data.spins_turns}
                      onChange={(...e) => {
                        handleDEdit("spins_turns", e);
                      }}
                    />
                  </Grid>
                  {/* Segunda columna */}
                  <Grid item xs>
                    <h4>Duracion de Animación</h4>
                    <Slider
                      className={classesSlider.root}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={1}
                      max={15}
                      value={data.spins_duration}
                      onChange={(...e) => {
                        handleDEdit("spins_duration", e);
                      }}
                    />
                  </Grid>
                </Grid>

                {/* Fila  4 */}
                <Grid container spacing={2}>
                  {/* Primera columna */}
                  <Grid item xs>
                    <h4>Tamaño de la fuente</h4>
                    <Slider
                      className={classesSlider.root}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={10}
                      max={60}
                      value={data.font_size}
                      onChange={(...e) => {
                        handleDEdit("font_size", e);
                      }}
                    />
                  </Grid>
                  {/* Segunda columna */}
                  <Grid item xs>
                    <h4> Tamaño del borde de la ruleta</h4>
                    <div>
                      <Slider
                        className={classesSlider.root}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={1}
                        max={22}
                        value={data.roulette_border_size}
                        onChange={(...e) => {
                          handleDEdit("roulette_border_size", e);
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                {/* Fila  5 */}
                <Grid container spacing={2}>
                  {/* Primera columna */}
                  <Grid item xs>
                    <h4>
                      {" "}
                      Color de la Ruleta 1
                      <div className="helpIcon">
                        <abbr title="Para insertar codigo, borrar el caracter  ' # ' ">
                          <Help color="primary" />
                        </abbr>
                      </div>
                    </h4>
                    <div>
                      <Block
                        color={data.wheel_color_1}
                        onChange={(color) => {
                          handleDEdit("wheel_color_1", [0, color.hex]);
                        }}
                      />
                    </div>
                  </Grid>
                  {/* Segunda columna */}
                  <Grid item xs>
                    <h4>
                      {" "}
                      Color de la Ruleta 2
                      <div className="helpIcon">
                        <abbr title="Para insertar codigo, borrar el caracter  ' # ' ">
                          <Help color="primary" />
                        </abbr>
                      </div>
                    </h4>
                    <Block
                      color={data.wheel_color_2}
                      onChange={(colors) => {
                        handleDEdit("wheel_color_2", [0, colors.hex]);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {/* Primera columna */}
                  <Grid item xs>
                    <h4>
                      {" "}
                      Color de la Fuente 1
                      <div className="helpIcon">
                        <abbr title="Para insertar codigo, borrar el caracter  ' # ' ">
                          <Help color="primary" />
                        </abbr>
                      </div>
                    </h4>
                    <div>
                      <Block
                        color={data.wheel_text_color_1}
                        onChange={(color) => {
                          handleDEdit("wheel_text_color_1", [0, color.hex]);
                        }}
                      />
                    </div>
                  </Grid>
                  {/* Segunda columna */}
                  <Grid item xs>
                    <h4>
                      {" "}
                      Color de la Fuente 2
                      <div className="helpIcon">
                        <abbr title="Para insertar codigo, borrar el caracter  ' # ' ">
                          <Help color="primary" />
                        </abbr>
                      </div>
                    </h4>
                    <Block
                      color={data.wheel_text_color_2}
                      onChange={(colors) => {
                        handleDEdit("wheel_text_color_2", [0, colors.hex]);
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            {/* Tercera Pestaña */}
            <TabPanel value={value} index={2}>
              {/* Fila  3 */}
              <Grid container spacing={6} className="scroller1" style={{justifyContent: "center"}}>
              
                <BackgroundList exportadata={ImportBackground}
                  itemData={generalSetting?.general?.backgrounds.map(
                    (items) => ({ img: items.url })
                  )}
                  testbackground={testbackground}
                />
                <br />
                
                <div className={classesBackground.root}>
            
                {uploadbackground ? ( 
                <Card style={{with: "70%"}} elevation={3}>
                  <CardMedia
                    component="img"
                    image={uploadbackground}
                    alt="New background"
                  />
                    {loadingSpinner && (<LinearProgress style={{marginLeft:"20px",marginRight:"20px"}}/> )}
                  <CardActions>
                    <Button size="small" disabled={loadingSpinner} onClick={()=>{sendImage()}}>Enviar</Button>
                    <Button size="small" disabled={loadingSpinner} onClick={()=>{setTestbackground("");setUploadbackground("");setUploadbackground64("")}}>Cancelar</Button>
                  </CardActions>
                </Card>
                ):(null)}

                </div>
              </Grid>
              <Grid container style={{textAlign:"center"}}>
              <Grid item xs={4}>
              </Grid>
              <input
                    accept="image/*"
                    className={classesBackground.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    accept=".jpg, .png, .jpeg, .gif"
                    onChange={(e)=>{
                      setUploadbackground(URL.createObjectURL(e.target.files[0])),
                      setUploadbackground64(convertImgToBase64(e))
                      }}
                  />
                 <Grid item xs={4}>
                  <label htmlFor="contained-button-file">
                    <br></br>
                    <IconButton 
                      variant="contained"
                      component="span">
                    <AddCircleOutline color="primary" fontSize="large" />
                    </IconButton>
                  </label>
                  </Grid>
                  <Grid item xs={4}>
                    {testbackground?
                    (<Button
                      variant="contained"
                      color="primary"
                      style={{marginTop: "20%"}}
                      onClick={()=>DeleteBackground(()=>{deleteImage()},()=>{console.log('cancel')})}
                    >
                      Eliminar imagen    
                    </Button>):null}
                  
                  </Grid>
                </Grid>
            </TabPanel>
          </Grid>
          {/* Vista Previa Ruleta en la columna derecha*/}
          <Grid item xs={6} style={{ textAlign:"-webkit-center"}}>
{
  testbackground===""?
  (
    <div id="background_Preview" style={{background:"rgb(191, 225, 255)",backgroundSize:"cover",maxWidth:"520px",borderRadius:"50px",marginTop:"-20px",border:"solid",borderRadius:"40px",borderColor:"white",borderWidth:"17px"}}>
          <RoulettePreview items={probabilitys.map((item) => item.label)} />
    </div>  
  )
  :
  (
    <div id="background_Preview" className="backgroundStyle" style={{background:"url("+testbackground+") center no-repeat",backgroundSize:"cover",maxWidth:"520px",borderRadius:"50px",marginTop:"-20px",border:"solid",borderRadius:"40px",borderColor:"white",borderWidth:"17px"}}>
            <RoulettePreview items={probabilitys.map((item) => item.label)} />
     </div>
  )
}
            {/* <div id="background_Preview" style={{background:"url("+testbackground+") center no-repeat",backgroundSize:"cover",maxWidth:"520px",borderRadius:"50px",marginTop:"-20px",border:"solid",borderRadius:"40px",borderColor:"white",borderWidth:"17px"}}> */}
            

          </Grid>
        </Grid>
      </div>

  );
}

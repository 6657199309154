import React from "react";
import swal from "@sweetalert/with-react";
import "./index.css";
import Confetti from "react-confetti";
export default class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      phone_Number: "",
      ci_Number: "",
      close_modal: false,
      confetti_bool: false,
    };
    this.selectItem = this.selectItem.bind(this);
  }

 

  data_Validation = (data1, data2) => {
    data1 !== "" && !isNaN(data1) && data2 !== "" && !isNaN(data2)
      ? swal({ icon: "success", buttons: false, timer: 2000 })
      : data1 === "" && data2 === ""
      ? (document.getElementById("error_ci_Numeric_Exit").style.display =
          "inline") &&
        (document.getElementById("error_phone_Numeric_Exit").style.display =
          "inline")
      : data1 === "" && data2 !== ""
      ? (document.getElementById("error_phone_Numeric_Exit").style.display =
          "inline")
      : data2 === ""
      ? (document.getElementById("error_ci_Numeric_Exit").style.display =
          "inline")
      : null;

      // data1!=="" ?
      //   (document.getElementById('error_ci_Numeric_Exit').style.display = 'inline')
      // :(data2!=="" ? (document.getElementById('error_phone_Numeric_Exit').style.display = 'inline'):(null))
  };

  phone_Numeric_Error_Message = (value) => {
    document.getElementById("error_phone_Numeric_Exit").style.display = "none";
    isNaN(value)
      ? value === null || value === ""
        ? (document.getElementById("error_phone_Numeric_Modal").style.display =
            "none")
        : (document.getElementById("error_phone_Numeric_Modal").style.display =
            "inline")
      : (document.getElementById("error_phone_Numeric_Modal").style.display =
          "none");
  };

  ci_Numeric_Error_Message = (value) => {
    document.getElementById("error_ci_Numeric_Exit").style.display = "none";
    isNaN(value)
      ? value === null || value === ""
        ? (document.getElementById("error_ci_Numeric_Modal").style.display =
            "none")
        : (document.getElementById("error_ci_Numeric_Modal").style.display =
            "inline")
      : (document.getElementById("error_ci_Numeric_Modal").style.display =
          "none");
  };

  selectItem() {
    if (this.state.selectedItem === null) {
      let selectedItem = Math.floor(Math.random() * 100);

      if (this.props.onSelectItem) {
        this.props.onSelectItem(selectedItem);
      }
      this.setState({ selectedItem });
      console.log("Score", selectedItem);
      switch (true) {
        case selectedItem <= 25:
          console.log("Premio 1");
          this.setState({ selectedItem: 0 });
          selectedItem = 0;
          break;
        case selectedItem > 25 && selectedItem <= 35:
          console.log("Premio 2");
          this.setState({ selectedItem: 1 });
          selectedItem = 1;
          break;
        case selectedItem > 35 && selectedItem <= 50:
          console.log("Premio 3");
          this.setState({ selectedItem: 2 });
          selectedItem = 2;
          break;
        case selectedItem > 50 && selectedItem <= 75:
          console.log("Premio 4");
          this.setState({ selectedItem: 3 });
          selectedItem = 3;
          break;
        case selectedItem > 75 && selectedItem <= 100:
          console.log("Premio 5");
          this.setState({ selectedItem: 4 });
          selectedItem = 4;

          break;
        default:
          break;
      }

      const { items } = this.props;
      console.log("listado de premios", items);
      if (selectedItem !== null) {
        setTimeout(() => {
          this.setState({ confetti_bool: true });
          swal(
            <div>
              {/* <Confetti
                  run={this.state.confetti_bool}
                  // width={1000}
                  // height={1000}
                  initialVelocityY= {18}
                  // initialVelocityX={15}
                  // confettiSource={10,0,0}
                  numberOfPieces={1700}
                   gravity={0.04}
                  className="confetti_drop"
                  /> */}
              <br></br>
              <div className="textmodal">
                <h2>Completa tus datos para recibir!!!</h2>
              </div>
              <a className="textmodal" href="https://www.wally.com.py">Bases y Condiciones</a>
              <br></br><br></br>
              <div>
                <label className="textmodal">
                  Numero de Telefono:
                  <input
                    type="text"
                    className="inputModal"
                    name="Telefono"
                    onChange={(value) => {
                      this.phone_Numeric_Error_Message(value.target.value);
                      this.setState(
                        { phone_Number: value.target.value },
                        console.log("Telefono", this.state.phone_Number)
                      );
                    }}
                  />
                </label>
              </div>
              <div>
                <span
                  id="error_phone_Numeric_Modal"
                  className="error_Text_Modal"
                >
                  *Numero de Telefono Invalido!
                </span>
                <span
                  id="error_phone_Numeric_Exit"
                  className="error_Text_Modal"
                >
                  *Campo Requerido!
                </span>
              </div>
              <br></br>
              <br></br>
              <div>
                <label className="textmodal">
                  Numero de Cedula:
                  <input
                    type="text"
                    className="inputModal"
                    name="Cedula"
                    onChange={(value) => {
                      this.ci_Numeric_Error_Message(value.target.value);
                      this.setState(
                        { ci_Number: value.target.value },
                        console.log("Cedula", this.state.ci_Number)
                      );
                    }}
                  />
                </label>
              </div>
              <div>
                <span id="error_ci_Numeric_Modal" className="error_Text_Modal">
                  *Numero de Cedula Invalido!
                </span>
                <span id="error_ci_Numeric_Exit" className="error_Text_Modal">
                  *Campo Requerido!
                </span>
              </div>
              {/* <div className="btnRetirar">
              <button className=".swal-button" onClick={()=>{cerrarModal=true;console.log("Valor",cerrarModal)}} >Retirar!</button>
            </div> */}
              <br></br>
              <button
                className="swal-button"
                onClick={() =>
                  this.data_Validation(
                    this.state.phone_Number,
                    this.state.ci_Number
                  )
                }
              >
                Aceptar
              </button>

              

            </div>,

            {
              title: "Felicidades!!!",
              text: "Ganaste " + items[selectedItem],
              style: "headermodal",
              closeOnClickOutside: false,
              closeOnEsc: false,
              close: this.state.close_modal,
              button: false,
            }

            // InputNumeroModal:{
            //   element:"input",
            //   attributes: {
            //     placeholder: "Ej.:0981000000",
            //     type: "password",
            //     className: "btnRetirar",
            //   },
            // },
            // },
          );
        }, 4000);
      }
    } else {
      this.setState({ selectedItem: null });
      setTimeout(this.selectItem, 500);
    }
  }

  render() {
    const { selectedItem } = this.state;
    const { items } = this.props;
    const wheelVars = {
      "--nb-item": items.length,
      "--selected-item": selectedItem,
    };
    const spinning = selectedItem !== null ? "spinning" : "";

    return (
      
      <div className="wheel-background">
        
        <div className="wheel-container">
          <div className={`wheel ${spinning}`} style={wheelVars}>
            {items.map((item, index) => (
              <div
                className="wheel-item"
                key={index}
                style={{ "--item-nb": index }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <br />
          <button
            id="btnAnimar"
            disabled={
              spinning === "spinning"
                ? (document
                    .getElementsByTagName("button")[0]
                    .setAttribute("class", "btnAfterClick"),
                  true)
                : false
            }
            onClick={this.selectItem}
            className="btn btn-warning btn-orange btn-lg"
          >
            GIRA LA RULETA
          </button>
        </div>
        <div >
                <Confetti
                  run={this.state.confetti_bool}
                  // width={1000}
                  // height={1000}
                  // initialVelocityY= {18}
                  // initialVelocityX={15}
                  // confettiSource={10,0,0}
                  numberOfPieces={1200}
                   gravity={0.25}
                  className="confetti_drop"
                  />
              </div>
      </div>
    );
  }
}

import * as React from 'react';
import  { useEffect, useState } from "react";

// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
export default function TitlebarImageList({
  itemData=[],
  exportadata=()=>{},
  testbackground=""
}) {

  const handleClick=(value)=>{
    exportadata(value)
  }

  const imageStyle = {overflow:"auto",textAlign: "center",width: "25%"}
  
  return (
    <ImageList sx={{ width: 500, height: 450 }} style={{paddingTop:"40px",marginLeft:"40px",marginRight:"40px"}} >
      
      {itemData.map((item) => (
        // <ToggleButton>
        <ImageListItem key={item.img} style={ testbackground==item.img ? {...imageStyle,boxShadow: '5px 5px 20px 1px #333'} : imageStyle }>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            loading="lazy"
            style={{cursor:"pointer",height: "100%",width: "100%",left: "0%"}}
            onClick={ ()=>{
              handleClick(item.img)
            }}
          />
          
        </ImageListItem>
        // </ToggleButton>
      ))}
    </ImageList>
    );
}
import React from "react";
import "./indexpreview.css";
export default class WheelPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      phone_Number: "",
      ci_Number: "",
      close_modal: false,
    };
    this.selectItem = this.selectItem.bind(this);
  }

  selectItem() {
    if (this.state.selectedItem === null) {
      let selectedItem = Math.floor(Math.random() * 100);
      

   

    } 
  }
  render() {
    const { selectedItem } = this.state;
    const { items } = this.props;
    const wheelVars = {
      "--nb-item": items.length,
      "--selected-item": selectedItem,
    };
    const spinning = selectedItem !== null ? "spinning" : "";
    

    return (
      <div className="wheel-background">
        <div className="wheel-container">
          <div className={`wheel ${spinning}`} style={wheelVars}>
            {items.map((item, index) => (
              <div
                className="wheel-item"
                key={index}
                style={{ "--item-nb": index }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <br />
          <button id="btnAnimar" onClick={this.selectItem} className="btn1">
            GIRA LA RULETA
          </button>
        </div>
      </div>
    );
  }
}
